class DataService {
  list = [];
  weeks = [];
  data = [];
  constructor(data) {
    this.list = data;
    this.formatData();
  }

  getWeeklyData(week) {
    return this.data.filter((item) => item.Which_week === week);
  }

  getWeeks() {
    return this.weeks;
  }

  formatData() {
    for (let index = 1; index < this.list.length; index++) {
      const element = this.list[index];
      let obj = {};
      for (let index2 = 0; index2 < element.length; index2++) {
        const value = element[index2];
        obj[this.list[0][index2].replaceAll(' ', '_')] = value;
      }
      this.data.push(obj);
      if (this.weeks.includes(obj.Which_week)) continue;
      this.weeks.push(obj.Which_week);
    }
  }


}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const dateFormater = (date) => {
  const d = new Date(date);
  return `${months[d.getMonth()]} ${d.getDate() + 1}, ${d.getFullYear()}`;
}


export const getCurrentWeek = () => {
  const baseStartDate = new Date(2024, 3, 29);
  const baseEndDate = new Date(2024, 4, 5);
  const today = new Date();


  //  write a fn to get the week number and the start date of the week and the end date of the week if start date is 29th of April 2024 and end date is 6th of May 2024 for week 18

  const weekNumber = Math.floor((today - baseStartDate) / (1000 * 60 * 60 * 24 * 7));

  const startDateOfCurrentWeek = new Date(baseStartDate.setDate(baseStartDate.getDate() + (weekNumber * 7) - 1));
  const endDateOfCurrentWeek = new Date(baseEndDate.setDate(baseEndDate.getDate() + (weekNumber * 7) - 1));

  return `Week ${weekNumber + 18} (${dateFormater(startDateOfCurrentWeek)} - ${dateFormater(endDateOfCurrentWeek)})`;

}

export default DataService;