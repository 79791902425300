import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { getCurrentWeek } from './formatter';

const RANGE = 'Challenges';

const ReviewForm = ({ idToken }) => {
  const [error, setError] = useState('');
  const [challenge, setChallenge] = useState('');
  const [currentDate] = useState(getCurrentWeek());
  const [dropdownItems, setDropdownItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    outcomes: '',
    lastWeekProgress: '',
    currentIssues: '',
    supportRequired: '',
    upcomingPlan: '',
    futureRisks: '',
    revision: 'No',
    revisionReason: '',
  });

  const handleSelect = (e) => {
    setChallenge(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (idToken) {
      const fetchData = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/data?range=${RANGE}`;
        try {
          const result = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          const data = result.data;
          data.shift();
          setDropdownItems(data.map((item) => [item[0], item[1]]));
        } catch (error) {
          setError(error.response?.data.toString());
        }
      };

      fetchData();
    }
  }, [idToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!challenge) {
      alert('Please select a challenge');
      setSubmitting(false);
      return;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/submit`;

    // Order should be maintained as per the sheet
    const values = [
      // Email will be added by the backend   #1
      currentDate, // #2
      challenge, // #3
      formData.revisionReason, // #4
      '', // 'Exp Date'  #5
      formData.outcomes, // #6
      formData.lastWeekProgress, // #7
      formData.currentIssues, // #8
      formData.revision, // #9
      formData.supportRequired, // #10
      formData.upcomingPlan, // #11
      formData.futureRisks, // #12
    ];
    try {
      await axios.post(
        url,
        {
          data: values,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      alert('Form submitted successfully');
      setSubmitting(false);
      setChallenge('');
      setFormData({
        outcomes: '',
        lastWeekProgress: '',
        currentIssues: '',
        supportRequired: '',
        upcomingPlan: '',
        futureRisks: '',
        revision: 'No',
        revisionReason: '',
      });
    } catch (error) {
      setSubmitting(false);
      setError(error.response?.data.error.message || error.message);
      console.error('Error details:', error.response?.data);
    }
  };

  if (error) {
    return <div className="text-danger text-center mt-5">{error}</div>;
  }

  return (
    <div className="container mt-4 mt-md-5">
      <Form className="mt-4" onSubmit={handleSubmit}>
        <Row className="">
          <Col xs={12} md={6} className="mb-4 ps-md-0">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="w-100"
                style={{ overflow: 'hidden' }}
              >
                {challenge || 'Select Challenge'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {dropdownItems.map((item, index) => (
                  <Dropdown.Item
                    style={{ overflow: 'hidden' }}
                    key={index}
                    eventKey={item[0] + (item[1] ? ' (' + item[1] + ')' : '')}
                  >
                    {item[0] + (item[1] ? ' (' + item[1] + ')' : '')}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            xs={12}
            md={6}
            className="mb-4 pe-md-0 d-flex align-items-center"
          >
            <Form.Group controlId="formRevision">
              <Form.Label className="fw-bold me-3">
                Revision Required:{' '}
              </Form.Label>
              <div className="d-inline">
                {['No', 'Yes'].map((rev, index) => (
                  <Form.Check
                    className="me-4"
                    inline
                    key={index}
                    type="radio"
                    label={rev}
                    name="revision"
                    value={rev}
                    checked={formData.revision === rev}
                    onChange={handleChange}
                  />
                ))}
              </div>
            </Form.Group>
          </Col>
        </Row>
        {formData.revision === 'Yes' && (
          <Row className="">
            <Col xs={12} className="mb-4 px-md-0">
              <Form.Group controlId="formRevision">
                <Form.Label className="fw-bold">Revision Reason</Form.Label>
                <Form.Control
                  required={true}
                  as="textarea"
                  rows={3}
                  name="revisionReason"
                  value={formData.revisionReason}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="">
          <Col xs={12} md={6} className="mb-4 ps-md-0">
            <Form.Group controlId="formOutcomes">
              <Form.Label className="fw-bold">Challenge Outcomes</Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="outcomes"
                value={formData.outcomes}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="mb-4 pe-md-0">
            <Form.Group controlId="formLastWeekProgress">
              <Form.Label className="fw-bold">Last week progress</Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="lastWeekProgress"
                value={formData.lastWeekProgress}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="">
          <Col xs={12} md={6} className="mb-4 ps-md-0">
            <Form.Group controlId="formCurrentIssues">
              <Form.Label className="fw-bold">
                Issues (Already facing - Current)
              </Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="currentIssues"
                value={formData.currentIssues}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="mb-4 pe-md-0">
            <Form.Group controlId="formSupportRequired">
              <Form.Label className="fw-bold">Support Required</Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="supportRequired"
                value={formData.supportRequired}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="">
          <Col xs={12} md={6} className="mb-4 ps-md-0">
            <Form.Group controlId="formUpcomingPlan">
              <Form.Label className="fw-bold">
                Plan for upcoming week
              </Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="upcomingPlan"
                value={formData.upcomingPlan}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="mb-4 pe-md-0">
            <Form.Group controlId="formFutureRisks">
              <Form.Label className="fw-bold">
                Risks (Expected Face - Future)
              </Form.Label>
              <Form.Control
                required={true}
                as="textarea"
                rows={5}
                name="futureRisks"
                value={formData.futureRisks}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="my-4 px-md-0">
            <Button
              disabled={submitting}
              variant="primary"
              type="submit"
              className="w-100"
            >
              {submitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ReviewForm;
