import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import DataService from './formatter';

const Preview = ({ idToken }) => {
  const [data, setData] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentWeek, setCurrentWeek] = useState('');
  const [weekData, setWeekData] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [currentUserData, setCurrentUserData] = useState({});
  const [error, setError] = useState('');



  useEffect(() => {
    if (idToken) {
      const fetchData = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/data`;
        try {
          const result = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          setData(result.data);
          const dataService = new DataService(result.data);
          setWeeks(dataService.getWeeks());
          setCurrentWeek(dataService.getWeeks()[dataService.getWeeks().length - 1]);
        } catch (error) {
          setError(error.response?.data.toString())
          console.error('Error details:', error.response?.data);
        }
      };

      fetchData();
    }
  }, [idToken]);



  useEffect(() => {
    if (currentWeek) {
      const dataService = new DataService(data);
      setWeekData(dataService.getWeeklyData(currentWeek));
      setCurrentWeek(currentWeek);
    }

  }, [currentWeek, data, weeks]);


  useEffect(() => {
    if (currentUser) {
      const user = weekData.find((item) => item.Email_Address === currentUser);
      setCurrentUserData(user);
    }
  }, [currentUser, weekData]);

  if (error) {
    return <div className='text-danger text-center mt-5'>{error}</div>;
  }


  return (
    <div className='container'>
      <Row className="mt-4 mt-md-5">
        <Col xs={12} md={6} className='mb-4 ps-md-0'>
          <Dropdown onSelect={(e) => setCurrentWeek(e)} >
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="w-100" style={{ overflow: 'hidden' }}>
              {currentWeek || 'Select Week'}
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>
              {weeks.map((item, index) => (
                <Dropdown.Item style={{ overflow: 'hidden' }} key={index} eventKey={item}>{item}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs={12} md={6} className='mb-4 pe-md-0'>
          <Dropdown onSelect={(e) => setCurrentUser(e)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="w-100" style={{ overflow: 'hidden' }}>
              {currentUser || 'Select User'}
            </Dropdown.Toggle>
            <Dropdown.Menu className='w-100'>
              {weekData.map((item, index) => (
                <Dropdown.Item style={{ overflow: 'hidden' }} key={index} eventKey={item.Email_Address}>{item.Email_Address}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <>

        {data.length > 0 ? (

          <div>
            {currentUserData && Object.keys(currentUserData).length > 0 ? (
              <Row className="">


                {Object.keys(currentUserData).map((key, index) => {
                  if (key === 'Email_Address' || key === 'Which_week' || key === 'Timestamp') return null;
                  return (
                    <Col xs={12} md={6} className={`mb-4 ${index % 2 === 0 ? 'pe-md-0' : 'ps-md-0'} `} key={index}>
                      <Card key={index} className=''>
                        <Card.Body style={{ minHeight: 120 }}>
                          <Card.Subtitle className='mb-1'>{key.replaceAll('_', ' ')}</Card.Subtitle>

                          <Card.Text className='text-secondary'>
                            {currentUserData[key].split('\n').map((item, index) => (
                              <span key={index}>{item}<br /></span>
                            ))}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  )
                })}
              </Row>


            ) : (
              <p className='text-center mt-4'>No data found</p>
            )}
          </div>

        ) : (
          <p className='text-center mt-4'>Loading...</p>
        )}
      </>

    </div>
  );
};

export default Preview;