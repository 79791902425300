import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import Preview from './Preview';
import ReviewForm from './ReviewForm';
import { getCurrentWeek } from './formatter';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
const REDIRECT_URI = process.env.NODE_ENV
  === 'development' ? 'http://localhost:3000' : 'https://weekly.soaron.co'

const App = () => {
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState(localStorage.getItem('idToken') || null);
  const [preview, setPreview] = useState(false);

  function parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }



  const isTokenExpired = useCallback((token) => {
    if (!token || token === '') {
      return true;
    }

    const decodedToken = parseJwt(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since epoch

    return decodedToken.exp < currentTime;
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      fetchTokens(response.code);
    },
    onError: (error) => console.error('Login failed', error),
    flow: 'code',
  });

  const fetchTokens = async (authorizationCode) => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        code: authorizationCode,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: REDIRECT_URI,
        grant_type: 'authorization_code',
      });
      const { id_token, refresh_token } = response.data;
      setIdToken(id_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('idToken', id_token);
      setIsAuthenticated(true);
    } catch (error) {
      setError(error.response?.data.error_description || error.message);
      console.error('Error fetching access token', error);
    }
  };


  const refreshIdToken = useCallback(async (refreshToken) => {
    console.log('Refreshing token');
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        refresh_token: refreshToken,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'refresh_token',
      });
      return response.data.id_token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  }, []);



  const checkTokenAndRefreshIfNeeded = useCallback(async (idToken) => {
    if (isTokenExpired(idToken)) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token found');
      }
      // Token expired, refresh it
      const newIdToken = await refreshIdToken(refreshToken);
      if (!newIdToken) {
        throw new Error('Failed to refresh token');
      }
      setIdToken(newIdToken);
      localStorage.setItem('idToken', newIdToken);
    }
  }, [isTokenExpired, refreshIdToken]);

  // Function to refresh token if needed

  const logout = () => {
    // setAccessToken('');
    setIdToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
  };



  React.useEffect(() => {
    const init = async () => {
      if (idToken) {
        await checkTokenAndRefreshIfNeeded(idToken).then(() => {
          setIsAuthenticated(true);
        }
        ).catch((error) => {
          setError(error.toString());
          logout();
        });
      }
      else {
        logout();
      }
    };
    init();
  }, [idToken, checkTokenAndRefreshIfNeeded]);


  return (
    <div >
      <Navbar variant='dark' bg='dark' >
        <div className='container px-md-0 flex-column'>
          <div className='justify-content-between d-flex w-100  align-items-center py-2' >
            <Navbar.Brand className='fs-5 fw-bold' href="/">Weekly Review</Navbar.Brand>
            <div className='text-secondary d-none d-md-block text-center' >{getCurrentWeek()} </div>
            {!isAuthenticated ? (
              <div>
                <Button variant='primary' onClick={login} >Login</Button>
              </div>
            ) :
              <div className='d-flex'>
                <Button className='me-2' variant='outline-success' onClick={() => preview ? setPreview(false) : setPreview(true)} >{preview ? 'Fill form' : 'Preview'}</Button>
                <div>{ }</div>
                <Button variant='danger' className='bg-transparent border-0 text-danger' onClick={logout} >Logout</Button>

              </div>
            }
          </div>
          <div className='text-secondary w-100 d-md-none text-start text-md-center pb-2' >{getCurrentWeek()} </div>
        </div>
      </Navbar>
      {
        error !== '' ? <div className='text-danger text-center mt-5'>{error}</div> :
          !isAuthenticated ? <div className='text-center mt-5'>Please login to continue</div>
            : preview ?
              <Preview idToken={idToken} /> : <ReviewForm idToken={idToken} />
      }
    </div>
  );
};

const Root = () => (
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <App />
  </GoogleOAuthProvider>
);

export default Root;